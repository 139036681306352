import React from "react";
import "../css/ComingSoon.css";
import logo from "../assets/img/t4a_logo_white.svg";

function LoginTemp() {
  return (
    <div className="page">
      <div className="coming-soon-message">Almost ready...</div>
      <img src={logo} alt="thenics4all Logo" className="coming-soon-logo" />
      <div className="coming-soon-subtitle">
        Registration opens shortly – stay tuned!!
      </div>
    </div>
  );
}

export default LoginTemp;
