import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Container, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { AiFillEdit } from 'react-icons/ai';
import { FaCog, FaYoutube } from 'react-icons/fa';
import defaultAvatar from '../assets/img/avatars/avatar_alien_white.png';
import badge1 from '../assets/img/badges/1_badge_rabbit.png';
import badge2 from '../assets/img/badges/2_badge_deer.png';
import badge3 from '../assets/img/badges/3_badge_wolf.png';
import badge4 from '../assets/img/badges/4_badge_horse.png';
import badge5 from '../assets/img/badges/5_badge_bear.png';
import badge6 from '../assets/img/badges/6_badge_gorilla.png';
import badge7 from '../assets/img/badges/7_badge_lion.png';
import '../css/ProfilePage.css';
import CardHSPUProgressProfile from './CardHSPUProgressProfile';
import data_program from '../data/data_program';
import data_user from '../data/data_user';
import ExistingUserSetupCard from './ExistingUserSetupCard';

function ProfilePage({ avatar: currentImage, setAvatar, originalImage, setOriginalImage }) {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage] = useState('');
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [journeyCompletion, setJourneyCompletion] = useState(0); // State for total journey completion
  const [currentBadge, setCurrentBadge] = useState(badge1); // State for current badge image
  const [badgeLevel, setBadgeLevel] = useState('Level 1'); // State for current badge level
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [LocalOriginalImage, setLocalOriginalImage] = useState(null); // Stores the original image file
  const [avatar, setAvatarState] = useState(currentImage || defaultAvatar);


  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleSettingsClose = () => {
    setShowSettingsModal(false);
  };

  const userProgressData = data_user[0].progressData; // Extracted dependency

  useEffect(() => {
    if (currentImage) {
      console.log('ProfilePage Avatar (currentImage) initialized:', currentImage);
      setAvatarState(currentImage); // Sync avatar state with the currentImage prop
    }
  }, [currentImage]);


  useEffect(() => {
    if (originalImage) {
      setLocalOriginalImage(originalImage); // Sync local state with parent-provided original image
    }
  }, [originalImage]);

  useEffect(() => {
    const calculateJourneyCompletion = () => {
      let totalProgress = 0;
      let maxPossibleProgress = 0;

      data_program.forEach((data) => {
        const userProgress = userProgressData.find(
          (item) => item.progress_code === data.progress_code
        );

        const progressPercentage = userProgress ? Math.min((userProgress.best / data.goal) * 100, 100) : 0;

        totalProgress += progressPercentage;
        maxPossibleProgress += 100; // Each task contributes 100% max to the journey
      });

      const totalJourneyPercentage = Math.min(Math.floor((totalProgress / maxPossibleProgress) * 100), 100);
      setJourneyCompletion(totalJourneyPercentage);
    };

    const updateBadge = () => {
      const badgeImages = [badge1, badge2, badge3, badge4, badge5, badge6, badge7];
      let highestSequentiallyCompletedLevel = -1;

      for (let level = 0; level < 7; level++) {
        const levelTasks = data_program.filter((data) => data.level === `Level ${level + 1}`);

        const levelComplete = levelTasks.every((data) => {
          const userProgress = userProgressData.find(
            (item) => item.progress_code === data.progress_code
          );

          const progressPercentage = userProgress ? Math.min((userProgress.best / data.goal) * 100, 100) : 0;
          return progressPercentage >= 100;
        });

        if (levelComplete) {
          highestSequentiallyCompletedLevel = level;
        } else {
          break;
        }
      }

      setCurrentBadge(badgeImages[highestSequentiallyCompletedLevel]);
      setBadgeLevel(`Level ${highestSequentiallyCompletedLevel + 1}`);
    };

    calculateJourneyCompletion();
    updateBadge();
  }, [userProgressData]);

  const getProgressBarColor = (percentage) => {
    if (percentage < 25) return '#E74C3C';
    if (percentage >= 25 && percentage < 50) return '#E67E22';
    if (percentage >= 50 && percentage < 75) return '#F1C40F';
    if (percentage >= 75 && percentage < 100) return '#3498DB';
    return '#2ECC71';
  };

  const handleRemoveAvatar = () => {
    setAvatar(defaultAvatar); // Reset avatar to default
    setOriginalImage(null); // Clear the original image
    setLocalOriginalImage(null); // Clear local original image
    setShowEditOptions(false); // Close edit options
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const getCroppedImg = async () => {
    if (!cropImage || !croppedAreaPixels) return null;

    const image = new Image();
    image.src = cropImage;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x * scaleX,
      croppedAreaPixels.y * scaleY,
      croppedAreaPixels.width * scaleX,
      croppedAreaPixels.height * scaleY,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          resolve(url);
        }
      }, 'image/jpeg');
    });
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setLocalOriginalImage(fileURL); // Update local original image
      setOriginalImage(fileURL); // Sync original image with parent
      setAvatar(fileURL); // Update avatar state
      setCropImage(fileURL); // Set the image for cropping
      setShowCropper(true); // Open cropper modal
      setShowEditOptions(true); // Go back to edit options
    }
  };

  const handleCropSave = async () => {
    const croppedImageURL = await getCroppedImg();
    if (croppedImageURL) {
      setAvatar(croppedImageURL); // Update avatar with cropped image
      // Do not modify `originalImage` here
    }
    setShowCropper(false); // Close cropper modal
    setShowEditOptions(false); // Ensure edit options modal does not reopen
  };

  const handleEditAvatar = () => {
    if (avatar && avatar !== defaultAvatar) {
      setShowEditOptions(true); // Open the edit options modal if avatar exists
    } else {
      document.getElementById('avatar-upload').click(); // Trigger file upload directly if no avatar
    }
  };

  const badges = [
    { level: 'Level 1', image: badge1, unlocked: true },
    { level: 'Level 2', image: badge2, unlocked: false },
    { level: 'Level 3', image: badge3, unlocked: false },
    { level: 'Level 4', image: badge4, unlocked: false },
    { level: 'Level 5', image: badge5, unlocked: false },
    { level: 'Level 6', image: badge6, unlocked: false },
    { level: 'Level 7', image: badge7, unlocked: false },
  ];

  const otherBadges = badges.filter((badge) => badge.level !== badgeLevel);

  return (
    <Container className="profilepage-card-container compact">
      <div className="profilepage-card-header">
        <span>My Profile</span>
        <Button variant="link" className="profilepage-settings-button" onClick={handleSettingsClick}>
          <FaCog className="profilepage-settings-icon" />
        </Button>
      </div>
      <div className="profilepage-card-content">
        <div className="profilepage-avatar-badge-container">
          <div className="profilepage-avatar-container">
            <label className="profilepage-avatar-label" onClick={handleEditAvatar}>
              <img src={avatar} alt="Avatar" className="profilepage-avatar-image" />
              <div className="profilepage-avatar-overlay">
                <AiFillEdit className="profilepage-avatar-edit-icon" />
              </div>
            </label>
            <input
              id="avatar-upload"
              type="file"
              accept="image/*"
              onChange={handleAvatarChange}
              style={{ display: 'none' }}
            />
            <div className="profilepage-handle-container">
              <h3>{user.username}</h3>
            </div>
          </div>

          <div className="profilepage-details-container">
            <div className="profilepage-badge-container">
              {otherBadges.slice(0, 6).map((badge, index) => (
                <OverlayTrigger
                  key={index}
                  placement="top"
                  overlay={<Tooltip>{badge.level}</Tooltip>}
                >
                  <img
                    src={badge.image}
                    alt={badge.level}
                    className={`profilepage-badge-image ${badge.unlocked ? '' : 'profilepage-locked-badge'}`}
                  />
                </OverlayTrigger>
              ))}
            </div>
          </div>

          <div className="profilepage-current-badge-container">
            <img
              src={currentBadge}
              alt={badgeLevel}
              className="profilepage-current-badge-image profilepage-no-circle"
            />
            <div className="profilepage-current-badge-label">{badgeLevel}</div>
          </div>
        </div>

        {/* YouTube Icon */}
        <div className="youtube-link-container">
          <a
            href="www.thenics4all.com" // Add your YouTube playlist URL here
            target="_blank"
            rel="noopener noreferrer"
            className="youtube-link"
          >
            <FaYoutube className="youtube-icon" />
          </a>
        </div>

        <div className="profilepage-journey-completion-container">
          <div className="profilepage-journey-completion-header">
            <div className="profilepage-journey-completion-label">My Journey</div>
            <div className="profilepage-journey-completion-percentage">{journeyCompletion}%</div>
          </div>
          <div className="profilepage-journey-completion-bar">
            <div
              className="profilepage-journey-completion-fill"
              style={{
                width: `${journeyCompletion}%`,
                backgroundColor: getProgressBarColor(journeyCompletion),
              }}
            ></div>
          </div>
        </div>

        <div className="profilepage-progress-card-container">
          <CardHSPUProgressProfile data_user={data_user.slice(0, 1)} />
        </div>
      </div>

      {/* Modals for settings */}
      <Modal show={showSettingsModal} onHide={handleSettingsClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExistingUserSetupCard
            avatar={avatar}
            setAvatar={setAvatar}
            originalImage={LocalOriginalImage}
            setOriginalImage={setLocalOriginalImage}
            onComplete={() => setShowSettingsModal(false)} // Close modal
            user={user} // Pass current user data
          />
        </Modal.Body>
      </Modal>

      {/* Modals for avatar editing, notifications, etc. */}
      <Modal show={showEditOptions} onHide={() => setShowEditOptions(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="danger"
            onClick={handleRemoveAvatar}
            style={{ marginRight: '10px' }}
          >
            Remove Image
          </Button>
          <Button
            variant="primary"
            onClick={() => document.getElementById('avatar-upload').click()}
            style={{ marginRight: '10px' }}
          >
            New Image
          </Button>
          <Button
            variant="info"
            onClick={() => {
              if (LocalOriginalImage) {
                setCropImage(LocalOriginalImage); // Use the original image for cropping
                setShowCropper(true); // Open the cropper modal
                setShowEditOptions(false); // Close the edit options modal
              }
            }}
          >
            Edit Image
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={showCropper} onHide={() => setShowCropper(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop your image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="profilepage-crop-modal-body">
          {cropImage && (
            <div className="profilepage-crop-container">
              <Cropper
                image={cropImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          )}
          <div className="profilepage-controls">
            <input
              type="range"
              className="profilepage-slider"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropper(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCropSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="profilepage-btn-home-link" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ProfilePage;
