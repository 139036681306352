import React, { useState, useCallback, useEffect } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { useAuth } from './AuthContext';
import '../css/NewUserSetupCard.css';
import { AiFillYoutube, AiFillInstagram, AiFillTwitterCircle, AiFillEdit } from 'react-icons/ai';
import { BsTiktok } from 'react-icons/bs';
import defaultAvatar from '../assets/img/avatars/avatar_alien_white.png';
import { updateUser } from './UpdateUser'; // Import updateUser function

function ExistingUserSetupCard({
  avatar,
  setAvatar = () => { }, // Default no-op function
  originalImage = null, // Default to null if not provided
  setOriginalImage = () => { }, // Default no-op function
  onComplete,
}) {
  const { user } = useAuth();
  const [username, setUsername] = useState(user.username);
  const [email, setEmail] = useState(user.email); // Pre-fill email
  const [youtube, setYoutube] = useState(user.youtube);
  const [instagram, setInstagram] = useState(user.instagram);
  const [tiktok, setTiktok] = useState(user.tiktok);
  const [twitter, setTwitter] = useState(user.twitter);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [LocalOriginalImage, setLocalOriginalImage] = useState(null);

  useEffect(() => {
    if (originalImage) {
      console.log('NewUserSetupCard Original Image initialized:', originalImage);
      setLocalOriginalImage(originalImage); // Sync local original image with prop
    }
  }, [originalImage]);

  const handleSave = async (e) => {
    e.preventDefault();
    const takenHandles = ['user1', 'admin', 'test'];

    if (takenHandles.includes(username)) {
      setModalMessage('Username is already taken. Please choose another one.');
      setShowModal(true);
    } else {
      const updates = {
        username,
        avatar, // Updated avatar
        email,
        youtube,
        instagram,
        tiktok,
        twitter,
      };

      try {
        // Call updateUser function to update the local data source
        updateUser(user.name, updates);

        // Notify parent component of completion
        onComplete();
      } catch (error) {
        setModalMessage(error.message);
        setShowModal(true);
      }
    }
  };

  const handleRemoveAvatar = () => {
    const defaultImage = defaultAvatar; // Use the default image
    setAvatar(defaultImage); // Set avatar to default image
    setOriginalImage(defaultImage); // Update the original image in parent
    setShowEditOptions(false); // Close edit options
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setLocalOriginalImage(fileURL); // Local copy for cropping
      setOriginalImage(fileURL); // Update original image in parent state
      setAvatar(fileURL); // Temporarily set as avatar
      setCropImage(fileURL); // Prepare for cropping
      setShowCropper(true); // Open the cropper modal
      setShowEditOptions(true); // Go back to edit options
    }
  };

  const handleCropSave = async () => {
    const croppedImageURL = await getCroppedImg(); // Generate cropped image
    if (croppedImageURL) {
      setAvatar(croppedImageURL); // Update avatar with the cropped image
      // Do not update `originalImage` here since it's the raw unedited image
    }
    setShowCropper(false); // Close the cropper modal
    setShowEditOptions(false); // Ensure edit options modal does not reopen
  };


  const getCroppedImg = async () => {
    if (!cropImage || !croppedAreaPixels) return null; // Ensure a valid crop area

    const image = new Image();
    image.src = cropImage;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x * scaleX,
      croppedAreaPixels.y * scaleY,
      croppedAreaPixels.width * scaleX,
      croppedAreaPixels.height * scaleY,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          resolve(url);
        }
      }, 'image/jpeg');
    });
  };

  const handleEditAvatar = () => {
    if (avatar && avatar !== defaultAvatar) {
      setShowEditOptions(true); // Open the edit options modal if avatar exists
    } else {
      document.getElementById('avatar-upload').click(); // Trigger file upload directly if no avatar
    }
  };

  const renderSocialMediaForm = () => {
    switch (activeTab) {
      case 'youtube':
        return (
          <Form.Group controlId="formYoutube" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="YouTube handle (Optional)"
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      case 'instagram':
        return (
          <Form.Group controlId="formInstagram" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="Instagram handle (Optional)"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      case 'tiktok':
        return (
          <Form.Group controlId="formTiktok" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="TikTok handle (Optional)"
              value={tiktok}
              onChange={(e) => setTiktok(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      case 'twitter':
        return (
          <Form.Group controlId="formTwitter" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="Twitter handle (Optional)"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  const [activeTab, setActiveTab] = useState('youtube');

  return (
    <Container className="newuser-profile-card-container compact">
      <div className="newuser-profile-card-header">
        <span>Set Up Your Profile</span>
      </div>
      <div className="newuser-profile-card-content">
        <Form onSubmit={handleSave}>
          <div className="newuser-avatar-container">
            <label className="newuser-avatar-label" onClick={handleEditAvatar}>
              <img
                src={avatar || defaultAvatar} // Use the current avatar or default
                alt="Avatar"
                className="newuser-avatar-image"
              />
              <div className="newuser-avatar-overlay">
                <AiFillEdit className="newuser-avatar-edit-icon" />
              </div>
            </label>
            <input
              id="avatar-upload"
              type="file"
              accept="image/*"
              onChange={handleAvatarChange}
              style={{ display: 'none' }}
            />
            <div className="newuser-handle-email-container">
              <Form.Group controlId="formHandle" className="newuser-profile-form-group">
                <Form.Control
                  type="text"
                  placeholder="my_username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)} // Correct this line
                  className="newuser-profile-form-control"
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="newuser-profile-form-group">
                <Form.Control
                  type="email"
                  placeholder="my@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="newuser-profile-form-control"
                />
              </Form.Group>
            </div>
          </div>
          <div className="newuser-tab-container">
            <Button className={`newuser-tab-button ${activeTab === 'youtube' ? 'active' : ''}`} onClick={() => setActiveTab('youtube')}>
              <AiFillYoutube className="newuser-tab-icon" />
            </Button>
            <Button className={`newuser-tab-button ${activeTab === 'instagram' ? 'active' : ''}`} onClick={() => setActiveTab('instagram')}>
              <AiFillInstagram className="newuser-tab-icon" />
            </Button>
            <Button className={`newuser-tab-button ${activeTab === 'tiktok' ? 'active' : ''}`} onClick={() => setActiveTab('tiktok')}>
              <BsTiktok className="newuser-tab-icon" />
            </Button>
            <Button className={`newuser-tab-button ${activeTab === 'twitter' ? 'active' : ''}`} onClick={() => setActiveTab('twitter')}>
              <AiFillTwitterCircle className="newuser-tab-icon" />
            </Button>
          </div>
          <div className="newuser-tab-content">{renderSocialMediaForm()}</div>
          <div className="newuser-button-group newuser-center-button">
            <Button className="newuser-btn-home-link" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
      {/* Modals for avatar editing, notifications, etc. */}
      <Modal show={showEditOptions} onHide={() => setShowEditOptions(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="danger"
            onClick={handleRemoveAvatar}
            style={{ marginRight: '10px' }}
          >
            Remove Image
          </Button>
          <Button
            variant="primary"
            onClick={() => document.getElementById('avatar-upload').click()}
            style={{ marginRight: '10px' }}
          >
            New Image
          </Button>
          <Button
            variant="info"
            onClick={() => {
              if (LocalOriginalImage) {
                setCropImage(LocalOriginalImage); // Always use original image for cropping
                setShowCropper(true); // Open cropper
                setShowEditOptions(false); // Close edit options modal
              }
            }}
          >
            Edit Image
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={showCropper} onHide={() => setShowCropper(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop your image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="profilepage-crop-modal-body">
          {cropImage && (
            <div className="profilepage-crop-container">
              <Cropper
                image={cropImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          )}
          <div className="profilepage-controls">
            <input
              type="range"
              className="profilepage-slider"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropper(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCropSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="newuser-btn-home-link" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ExistingUserSetupCard;
