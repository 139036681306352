import React, { useRef, useState, useEffect } from "react";
import styles from "../css/CardHSPUProgressProfile.module.css";
import { BsFillPlayBtnFill, BsPlusCircleFill } from "react-icons/bs";
import data_program from "../data/data_program";
import { FaLock, FaUnlockAlt } from "react-icons/fa";
import logo from "../assets/img/t4a_logo_white.svg";

function CardHSPUProgressProfile({ data_user }) {
  const cardRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (showVideo && cardRef.current && !cardRef.current.contains(event.target)) {
        closeVideo();
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showVideo]);

  const openVideo = (url) => {
    setVideoUrl(url || "");
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  // Determine the next level that should show the unlock icon
  const determineUnlockedLevel = () => {
    let highestSequentiallyCompletedLevel = -1;

    for (let level = 0; level < 7; level++) {
      const levelTasks = data_program.filter((data) => data.level === `Level ${level + 1}`);
      const levelComplete = levelTasks.every((data) => {
        const userProgress = data_user[0].progressData.find(
          (item) => item.progress_code === data.progress_code
        );
        const progressPercentage = userProgress
          ? Math.min((userProgress.best / data.goal) * 100, 100)
          : 0;
        return progressPercentage >= 100;
      });

      if (levelComplete) {
        highestSequentiallyCompletedLevel = level;
      } else {
        break;
      }
    }

    const unlockedLevelIndex = highestSequentiallyCompletedLevel + 2;
    console.log(`Unlocked Level Index: ${unlockedLevelIndex}`);
    return unlockedLevelIndex;
  };

  const unlockedLevelIndex = determineUnlockedLevel();

  return (
    <div className={styles.card} ref={cardRef}>
      <table className={styles.cardTable}>
        <thead>
          <tr>
            <th className={styles.firstCol}>Progression</th>
            <th className={styles.secondCol}>Progress</th>
          </tr>
        </thead>
        <tbody>
          {data_program.map((data, dataIndex) => {
            const level = parseInt(data.progress_code.charAt(1), 10);
            console.log(`Row ${dataIndex + 1}: Level = ${level}, Unlocked Level Index = ${unlockedLevelIndex}`);

            return (
              <tr key={dataIndex} className={`${styles.level}-${level}`}>
                <td
                  className={`${styles.firstCol} ${styles.progressionCell}`}
                  style={{
                    background: data.color,
                    color: data.color === "white" ? "black" : "white",
                    textAlign: "left",
                  }}
                  onClick={() => openVideo(data.videoUrl)}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{data.progression}</span>
                    <button
                      className={styles.watchVideoButton}
                      style={{
                        color: data.color === "white" ? "black" : "white",
                        marginLeft: "auto",
                      }}
                    >
                      <BsFillPlayBtnFill className={styles.watchVideoIcon} />
                    </button>
                  </div>
                </td>

                {data_user.slice(0, 1).map((user, userIndex) => {
                  const userProgress = user.progressData.find(
                    (item) => item.progress_code === data.progress_code
                  );
                  const progressPercentage = userProgress
                    ? Math.min((userProgress.best / data.goal) * 100, 100)
                    : 0;

                  return (
                    <React.Fragment key={userIndex}>
                      <td
                        className={`${styles.secondCol}`}
                        style={{
                          background: data.color,
                          color: data.color === "white" ? "black" : "white",
                          width: "150px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {userProgress && userProgress.best > 0 ? (
                            <div className={styles.progressBar}>
                              <span className={styles.progressPercentageLeft}>
                                {userProgress.best}
                              </span>
                              <div
                                className={styles.progressFill}
                                style={{
                                  width: `${progressPercentage}%`,
                                  backgroundColor:
                                    progressPercentage < 25
                                      ? "#E74C3C"
                                      : progressPercentage >= 25 && progressPercentage < 50
                                      ? "#E67E22"
                                      : progressPercentage >= 50 && progressPercentage < 75
                                      ? "#F1C40F"
                                      : progressPercentage >= 75 && progressPercentage < 100
                                      ? "#3498DB"
                                      : "#2ECC71",
                                }}
                              ></div>
                              <span className={styles.progressPercentageRight}>
                                {`${progressPercentage.toFixed(0)}%`}
                              </span>
                            </div>
                          ) : (
                            <div className={styles.lockedIcon}>
                              {level === unlockedLevelIndex ? (
                                <>
                                  <FaUnlockAlt className={styles.unlockedIcon} />
                                  <button
                                    className={styles.plusIconButton}
                                    style={{ color: "inherit" }}
                                    onClick={() => openVideo(null)}
                                  >
                                    <BsPlusCircleFill className={styles.plusIcon} />
                                  </button>
                                </>
                              ) : (
                                <FaLock style={{ color: "gray" }} />
                              )}
                            </div>
                          )}
                          {userProgress && userProgress.best > 0 && (
                            <>
                              <button
                                className={styles.watchVideoButton}
                                style={{
                                  color: data.color === "white" ? "black" : "white",
                                }}
                                onClick={() =>
                                  userProgress.best_url
                                    ? openVideo(userProgress.best_url)
                                    : openVideo(null)
                                }
                              >
                                <BsFillPlayBtnFill className={styles.watchVideoIcon} />
                              </button>
                              <button
                                className={styles.plusIconButton}
                                style={{
                                  color: data.color === "white" ? "black" : "white",
                                }}
                                onClick={() => openVideo(null)}
                              >
                                <BsPlusCircleFill className={styles.plusIcon} />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {showVideo && (
        <div className={styles.videoPopup}>
          <div className={styles.videoCloseButton} onClick={closeVideo}>
            &times;
          </div>
          {videoUrl ? (
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <>
              <div className={styles.comingSoonMessage}>Coming soon!</div>
              <img src={logo} alt="thenics4all Logo" className={styles.logo} />
              <div className={styles.comingSoonMessageSubtitle}>
                Working on recording the video!
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CardHSPUProgressProfile;
