import React, { useState } from 'react';
import { Container, Button, Modal, Form } from 'react-bootstrap';
import NewUserSetupCard from './NewUserSetupCard';
import ProfilePage from './ProfilePage';
import data_user from '../data/data_user';
import defaultAvatar from '../assets/img/avatars/avatar_alien_white.png';

function MockSignInPage() {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInError, setSignInError] = useState('');
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [originalImage, setOriginalImage] = useState(defaultAvatar);
  const [currentImage, setCurrentImage] = useState(defaultAvatar);

  const handleSignIn = () => {
    const existingUser = data_user.find(
      (user) =>
        (user.username === usernameOrEmail || user.email === usernameOrEmail) &&
        user.password === password
    );

    if (existingUser) {
      setCurrentUser(existingUser);
      setAvatar(existingUser.avatar || defaultAvatar);
      setOriginalImage(existingUser.avatar || defaultAvatar);
      setCurrentImage(existingUser.avatar || defaultAvatar);
      setShowSignInModal(false); // Close modal on successful sign-in
    } else {
      setSignInError('Invalid username/email or password');
    }
  };

  const handleNewUserSignup = () => {
    setShowSignUp(true); // Show New User Setup Card
  };

  const handleUserCreated = (newUser) => {
    data_user.push(newUser); // Add new user to data_user
    setCurrentUser(newUser); // Set current user
    setAvatar(newUser.avatar || defaultAvatar);
    setOriginalImage(newUser.avatar || defaultAvatar);
    setCurrentImage(newUser.avatar || defaultAvatar);
    setShowSignUp(false); // Exit signup view
  };

  return (
    <div className="page">
      <Container fluid className="auth-container">
        {!currentUser && !showSignUp ? (
          <div className="center-button-container">
            <Button className="mock-sign-link" onClick={handleNewUserSignup}>
              New User Sign up
            </Button>
            <Button className="mock-sign-link" onClick={() => setShowSignInModal(true)}>
              Mock Sign-In
            </Button>
          </div>
        ) : showSignUp ? (
          <NewUserSetupCard
            avatar={currentImage}
            setAvatar={(newImage) => {
              setCurrentImage(newImage);
              setAvatar(newImage);
            }}
            originalImage={originalImage}
            setOriginalImage={(newOriginal) => {
              setOriginalImage(newOriginal);
              setCurrentImage(newOriginal);
              setAvatar(newOriginal);
            }}
            onUserCreated={handleUserCreated}
          />
        ) : (
          <ProfilePage
            avatar={currentImage}
            setAvatar={(newImage) => {
              setCurrentImage(newImage);
              setAvatar(newImage);
            }}
            originalImage={originalImage}
            setOriginalImage={(newOriginal) => {
              setOriginalImage(newOriginal);
              setCurrentImage(newOriginal);
              setAvatar(newOriginal);
            }}
            user={currentUser}
          />
        )}
      </Container>

      {/* Sign-In Modal */}
      <Modal show={showSignInModal} onHide={() => setShowSignInModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mock Sign-In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mock-signin-form">
            <Form.Group controlId="formUsernameOrEmail">
              <Form.Label>Username or Email</Form.Label>
              <Form.Control
                type="text"
                value={usernameOrEmail}
                onChange={(e) => setUsernameOrEmail(e.target.value)}
                placeholder="Enter username or email"
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
            </Form.Group>
            {signInError && <p style={{ color: 'red' }}>{signInError}</p>}
            <Button className="mock-signin-button" onClick={handleSignIn}>
              Sign In
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MockSignInPage;
