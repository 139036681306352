import data_user from '../data/data_user';

// Update user function
export const updateUser = (name, updates) => {
  const userIndex = data_user.findIndex((user) => user.name === name);
  if (userIndex === -1) {
    throw new Error(`User with name ${name} not found`);
  }

  // Update the user data
  data_user[userIndex] = { ...data_user[userIndex], ...updates };

  // Return the updated user
  return data_user[userIndex];
};