const data_user = [
  {
    username: "user",
    email: "user@example.com",
    password: "pw",
    avatar: "../assets/img/avatars/avatar_alien_white.png",
    disclaimerAccepted: false,
    disclaimerAcceptedDate: null,
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2024-10-10", last: 10, last_date: "2024-10-09", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2024-10-08", last: 33, last_date: "2024-10-07", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2024-10-06", last: 10, last_date: "2024-10-05", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2024-10-05", last: 6, last_date: "2024-10-04", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2024-10-03", last: 50, last_date: "2024-10-02", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2024-10-01", last: 8, last_date: "2024-09-30", best_url: null },
      { progress_code: "l3a", best: 7, best_date: "2024-09-28", last: 3, last_date: "2024-09-27", best_url: null },
      { progress_code: "l3b", best: 53, best_date: "2024-09-25", last: 14, last_date: "2024-09-24", best_url: null },
      { progress_code: "l3c", best: 0, best_date: "2024-09-22", last: 6, last_date: "2024-09-21", best_url: null },
      { progress_code: "l4a", best: 0, best_date: "2024-09-20", last: 0, last_date: "2024-09-19", best_url: null },
      { progress_code: "l4b", best: 0, best_date: "2024-09-18", last: 0, last_date: "2024-09-17", best_url: null },
      { progress_code: "l4c", best: 0, best_date: "2024-09-15", last: 0, last_date: "2024-09-14", best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "zarrar",
    email: "user@example.com",
    password: "pw",
    avatar: "../assets/img/avatars/avatar_alien_white.png",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2024-10-01",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2024-10-09", last: 23, last_date: "2024-10-08", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2024-10-07", last: 58, last_date: "2024-10-06", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2024-10-05", last: 14, last_date: "2024-10-04", best_url: null },
      { progress_code: "l2a", best: 12, best_date: "2024-10-03", last: 12, last_date: "2024-10-02", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2024-10-02", last: 60, last_date: "2024-10-01", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2024-10-01", last: 9, last_date: "2024-09-30", best_url: null },
      { progress_code: "l3a", best: 10, best_date: "2024-09-29", last: 10, last_date: "2024-09-28", best_url: null },
      { progress_code: "l3b", best: 60, best_date: "2024-09-28", last: 58, last_date: "2024-09-27", best_url: null },
      { progress_code: "l3c", best: 10, best_date: "2024-09-26", last: 10, last_date: "2024-09-25", best_url: null },
      { progress_code: "l4a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
];

export default data_user;
